import { Expose } from 'class-transformer'

export class EventsDto {
    @Expose()
    events: EventDto[];

    constructor(events: EventDto[]) {
        this.events = events
    }
}

export class EventDto {
    @Expose()
    env: string;

    @Expose({ name: 'event_name' })
    eventName: string;

    @Expose({ name: 'event_params' })
    eventParams: EventParamsDto[]

    constructor(env: string, eventName: string, eventParams: EventParamsDto[]) {
        this.env = env
        this.eventName = eventName
        this.eventParams = eventParams
    }
}

export class EventParamsDto {
    @Expose()
    key: string;

    @Expose()
    value: EventValueDto;

    constructor(key: string, value: EventValueDto) {
        this.key = key
        this.value = value
    }
}

export class EventValueDto {
    @Expose({ name: 'int_value' })
    intValue: number | null

    @Expose({ name: 'string_value' })
    stringValue: string | null

    constructor(intValue: number | null, stringValue: string | null) {
        this.intValue = intValue
        this.stringValue = stringValue
    }
}