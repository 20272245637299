import {Expose} from 'class-transformer';
import {QrContext} from '../domain/QrContext';

export class QrContextDto {
    @Expose({name: 'content'})
    contentId: string;

    @Expose()
    env: string;

    @Expose()
    url: string;

    @Expose({name: 'ver_code'})
    versionCode: number;

    @Expose({name: 'ad_grp'})
    adGroupKey: number;

    @Expose({name: 'creative_grp'})
    creativeGroupKey: number;

    @Expose({name: 'ad_unit_c'})
    adUnitCode: string;

    @Expose({name: 'ch'})
    channelId: string;

    @Expose({name: 'lpeid'})
    landingPageEventId: string;

    constructor() {
    }

    toDomain() {
        return new QrContext(
            this.contentId,
            this.env,
            this.url,
            this.versionCode,
            this.adGroupKey,
            this.creativeGroupKey,
            this.adUnitCode,
            this.channelId,
            this.landingPageEventId
        );
    }
}
