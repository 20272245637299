export enum QrType {
    'Content',
    'Channel'
}

export class QrContext {
    public contentId: string;
    public env: string;
    public url: string;
    public versionCode: number;
    public adGroupKey: number;
    public creativeGroupKey: number;
    public adUnitCode: string;
    public channelId: string;
    public landingPageEventId: string;

    constructor(
        contentId: string,
        env: string,
        url: string,
        versionCode: number,
        adGroupKey: number,
        creativeGroupKey: number,
        adUnitCode: string,
        channelId: string,
        landingPageEventId: string
    ) {
        this.contentId = contentId;
        this.env = env;
        this.url = url;
        this.versionCode = versionCode;
        this.adGroupKey = adGroupKey;
        this.creativeGroupKey = creativeGroupKey;
        this.adUnitCode = adUnitCode;
        this.channelId = channelId;
        this.landingPageEventId = landingPageEventId;
    }

    isValid() {
        return this.env !== undefined
            && this.url !== undefined
            && this.versionCode !== undefined
    }
}
