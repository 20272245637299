import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import {sentryDsn, env} from './lib/constant';
import './index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import reportWebVitals from './reportWebVitals';

Sentry.init({
  dsn: sentryDsn,
  release: "0.1.5",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.2,
  environment: env,
})

ReactDOM.render(
    <BrowserRouter>
        <App/>
    </BrowserRouter>,
    document.getElementById("root"));

reportWebVitals();