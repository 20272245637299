export const baseUrl = process.env.REACT_APP_AD_EVENT_API_URL as string;

export const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  Authorization: process.env.REACT_APP_API_KEY as string,
};

export const env = process.env.REACT_APP_ENV as string;
export const adPlatformGetApiUrl = baseUrl + "/api/v1/rse/landing-url/";
export const adPlatformHitApiUrl = baseUrl + "/api/v1/rse/app-events";

export const sentryDsn = process.env.REACT_APP_SENTRY_DSN as string;