import axios, {AxiosResponse} from 'axios';
import {adPlatformGetApiUrl, adPlatformHitApiUrl, headers} from '../lib/constant';
import {Data} from '../model/response';
import {QrContext, QrType} from '../model/domain/QrContext';
import {EventDto, EventParamsDto, EventsDto, EventValueDto,} from '../model/dto/EventDto';
import {instanceToPlain} from 'class-transformer';
import * as Sentry from '@sentry/react';

export const read = async (shortenUrl: string) => {
    try {
        const response: AxiosResponse<Data> = await axios.get(
            adPlatformGetApiUrl + shortenUrl,
            {
                headers: headers,
            }
        );
        return response.data.data.landing_url
    } catch (e) {
        if(axios.isAxiosError(e)) {
            Sentry.captureException(`Error Read:${e}` )
        } else {
            Sentry.captureException(`Error Read: unknown ${e}`)
        }
    }
};

export const hit = async (qrContext: QrContext, type: QrType) => {
    try {
        const response: AxiosResponse<any> = await axios.post(
            adPlatformHitApiUrl,
            qrContextToJson(qrContext, type),
            {
                headers: headers,
            }
        );
        return qrContext.url
    } catch (e) {
        if(axios.isAxiosError(e)) {
            Sentry.captureException(`Error Hit:${e}` )
        } else {
            Sentry.captureException(`Error Hit: unknown ${e}`)
        }
    }
}

function qrContextToJson(qrContext: QrContext, type: QrType) {
    const eventsDto = getEventsDto(qrContext, type);
    return JSON.stringify(instanceToPlain(eventsDto));
}

function getEventsDto(qrContext: QrContext, type: QrType) {
    return new EventsDto(getEventDto(qrContext, type));
}

function getEventDto(qrContext: QrContext, type: QrType) {
    return [
        new EventDto(
            qrContext.env,
            'landing_page_view',
            getEventParamsDto(qrContext, type)
        ),
    ];
}

function getEventParamsDto(qrContext: QrContext, type: QrType) {
    if (type == QrType.Channel) {
        return [
            new EventParamsDto(
                'version_code',
                new EventValueDto(qrContext.versionCode, null)
            ),
            new EventParamsDto(
                'channel_id',
                new EventValueDto(null, qrContext.channelId)
            ),
            new EventParamsDto(
                'landing_page_event_id',
                new EventValueDto(null, qrContext.landingPageEventId)
            )
        ]
    } else {
        return [
            new EventParamsDto(
                'content_id',
                new EventValueDto(null, qrContext.contentId)
            ),
            new EventParamsDto(
                'version_code',
                new EventValueDto(qrContext.versionCode, null)
            ),
            new EventParamsDto(
                'ad_group_key',
                new EventValueDto(qrContext.adGroupKey, null)
            ),
            new EventParamsDto(
                'creative_group_key',
                new EventValueDto(qrContext.creativeGroupKey, null)
            ),
            new EventParamsDto(
                'ad_unit_code',
                new EventValueDto(null, qrContext.adUnitCode)
            ),
            new EventParamsDto(
                'landing_page_event_id',
                new EventValueDto(null, qrContext.landingPageEventId)
            )
        ];
    }
}