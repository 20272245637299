import React from 'react';
import loadingImg from './loading.gif';
import './App.css';
import {useEffect, useState} from 'react';
import * as Sentry from '@sentry/react';
import {env} from './lib/constant';
import {useLocation} from 'react-router';
import queryString from 'query-string';
import {QrContextDto} from './model/dto/QrContextDto';
import {plainToClass} from 'class-transformer';
import {read, hit} from './repository/shortenUrl'
import {QrContext, QrType} from './model/domain/QrContext';


enum State {
    'init',
    'success',
    'failed',
    'failed_empty',
}

enum UrlContextState {
    'empty',
    'shortenUrl',
    'shortenUrlNotValid',
    'queryString',
    'queryStringNotValid',
}

interface UiState {
    state: State,
    url: string
}

function App() {
    const location = useLocation();
    const pathName = location.pathname.substring(1);
    const query = queryString.parse(location.search);

    const [uiState, setUiState] = useState<UiState>({state: State.init, url: ""});

    useEffect(() => {
        console.log(`${env} - v2024.04`);

        const qrContext = plainToClass(QrContextDto, query, {
            strategy: 'excludeAll',
        }).toDomain();

        const shortenUrl = pathName;

        const urlContextState = getUrlContextState(pathName, location.search, qrContext);

        console.log(urlContextState)

        if (urlContextState === UrlContextState.queryStringNotValid || urlContextState === UrlContextState.shortenUrlNotValid) {
            console.log("not valid")
            Sentry.captureException("Not Valid QueryString")
        }

        if (urlContextState === UrlContextState.shortenUrl) {
            read(shortenUrl).then(result => {
                if (result !== undefined) {
                    setUiState({state: State.success, url: result})
                } else {
                    setUiState({state: State.failed, url: ""})
                }
            })
        } else if (urlContextState === UrlContextState.queryString) {
            let qrType;
            if (qrContext.channelId !== undefined && qrContext.channelId !== "") {
                qrType = QrType.Channel
            } else {
                qrType = QrType.Content
            }
            hit(qrContext, qrType).then(result => {
                if (result !== undefined) {
                    let url = result
                    if (!url.includes('http'))
                        url = "https://" + url
                    setUiState({state: State.success, url: url})
                } else {
                    setUiState({state: State.failed, url: ""})
                }
            })
        } else {
            setUiState({state: State.failed, url: ""})
        }
    }, [])

    return (
        <div className='App'>
            <header className='App-header'>
                <img src={loadingImg} className='App-logo' alt='logo'/>
                <p className='loading-text'>{description(uiState)}</p>
            </header>
        </div>
    );
}

const description = (uiState: UiState) => {
    const state = uiState.state
    const url = uiState.url

    if (state === State.success) {
        window.location.replace(url);
        return '해당 사이트로 이동합니다!'
    } else if (state === State.failed) {
        return '앗! URL이 존재하지 않습니다.'
    }

    return '잠시만 기다려주세요'
}

function getUrlContextState(pathName: string, query: string, qrContext: QrContext) {
    if (pathName === '' && query === '') return UrlContextState.empty;
    else if (pathName === '' && query !== '')
        if (qrContext.isValid())
            return UrlContextState.queryString;
        else
            return UrlContextState.queryStringNotValid;
    else if (pathName !== '' && query === '')
        if (isValidShortenUrl(pathName))
            return UrlContextState.shortenUrl
        else
            return UrlContextState.shortenUrlNotValid

    else return UrlContextState.empty;
}

function isValidShortenUrl(pathName: string) {
    const base62RegExp = /^[0-9A-Za-z]*$/g
    return pathName.match(base62RegExp) != null
}

export default Sentry.withProfiler(App);